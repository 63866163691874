<template>
    <div class="app-container">
        <p class="container-title">
            {{$t("emergencyContact.headtitle",{name:patientName})}} <br>
            {{$t("emergencyContact.headtitle2")}}
        </p>
        <p class="yam-box" v-if="emergencyContactMethod1">
            <span class="txt">{{$t("emergencyContact.yzm")}} {{securityCode}}</span>
            <span class="copy" v-if="securityCode" @click="copyText">{{$t("emergencyContact.copy")}}</span>
        </p> 
        <div class="yzm-container wayone" v-if="emergencyContactMethod1">
            <p class="mode" >方式1</p>
            <div class="desc" v-html="emergencyContactMethod1.replace(/\n/g, '<br/>')"></div>
        </div>
        <div class="yzm-container">
            <p class="mode" v-if="emergencyContactMethod1">方式2</p>
            <p class="yam-txt" v-if="!emergencyContactMethod1">
                <span class="txt">{{$t("emergencyContact.yzm")}} {{securityCode}}</span>
                <span class="copy" v-if="securityCode" @click="copyText">{{$t("emergencyContact.copy")}}</span>
            </p>
            <p class="desc-title">{{$t("emergencyContact.descTitle")}}</p>
            <div class="hr-box" v-if="!emergencyContactMethod1"></div>
            <div class="yzm">
                <img :src="'/api/cusapi/event/qr/'+eventId+'.jpg'" >
            </div>
            <div class="steps">
                <p class="item">
                    <span class="label">1</span>
                    <span class="txt">{{$t("emergencyContact.txt1")}}</span>
                </p>
                <p class="item">
                    <span class="label">2</span>
                    <span class="txt">{{$t("emergencyContact.txt2")}}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { config_get } from "@/api/user"
export default {
    data () {
        return {
            securityCode:this.$route.query.securityCode,
            patientName:this.$route.query.patientName,
            eventId:this.$route.query.eventId,
            emergencyContactMethod1:""
        }
    },
    created () {
        document.title = this.$t("emergencyContact.name")
        // this.getgzh()
        config_get({}).then(res =>{
            if(res.data.errorCode == 0&&res.data){
                this.emergencyContactMethod1 = res.data.data.emergencyContactMethod1
            }
        })
    },
    methods:{
        copyText() {
            var oInput = document.createElement('input');     //创建一个隐藏input
            oInput.value = this.securityCode;    //赋值
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            oInput.className = 'oInput';
            oInput.style.display='none';
            oInput.remove();
            this.$toast(this.$t("comonTxt.copysuccess"))
        },
    }
}
</script>

<style lang="less" scoped>
.app-container{
    height: 100%;
    width: 100%;
    background: url("~@/assets/img/emergencyContact.png") no-repeat;
    background-size: 100% 7.8rem;
    background-position: 0 0;
    background-color: #4AA9FF;
    overflow: auto;
    .container-title{
        font-size: 0.84rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FEFEFE;
        line-height: 1.2rem;
        text-shadow: 0px 0.06rem 0.12rem #054DD2; 
        margin: 1.54rem auto 1rem 1.2rem;
    }
    .yzm-container{
        margin: 0 0.8rem 0.4rem 0.8rem;
        background: #FFFFFF;
        padding: 2rem 0.65rem 1.2rem 0.65rem;
        position: relative;
        border-radius: 20px;
        box-shadow: -0.04rem 0 0.2rem #cce1f5 inset;
        .desc{
            font-size: 0.6rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 0.84rem;
        }
        .mode{
            position: absolute;
            background: #D0E9FF;
            top: 0;
            left: 0;
            height: 1.2rem;
            border-radius: 0.4rem 0 0.4rem 0;
            padding: 0 0.6rem;
            line-height: 1.2rem;
            font-size: 0.72rem;
            font-family: PingFang SC;
            font-weight: 600;
            font-style: italic;
            color: #49A3FF;
        }
        .yam-txt{
            display: flex;
            align-items: center;
            justify-content: center;
            .txt{
                font-size: 1.12rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
            }
            .copy{
                padding: 0.2rem 0.48rem;
                font-size: 0.56rem;
                font-family: PingFang SC;
                font-weight: 500;
                background: #49A3FF;
                border-radius: 0.5rem;
                margin-left: 0.2rem;
                color: #FFFFFF;
            }
        }
        .desc-title{
            width: max-content;
            padding: 0 1.2rem;
            background: url("~@/assets/img/desc-title-back.png") no-repeat;
            background-size: 100% auto;
            font-size: 0.56rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 0.6rem;
            margin: 0.6rem auto 0.4rem auto;
            background-position: bottom;
        }
        .hr-box{
            width: 10.8rem;
            border-bottom: 0.06rem dashed #AEB7B9;
            margin: 1rem auto;
            position: relative;
            &::after,&::before{
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0,-50%);
                left: -15%;
                background: #00abff;
            }
            &::before{
                left: auto;
                right: -15%;
            }
        }
        .yzm{
            width: 6.64rem;
            height: 6.76rem;
            margin: 0 auto 1rem auto;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .steps{
            .item{
                display: flex;
                align-items: flex-start;
                margin-bottom: 0.6rem;
                &:last-child{
                    margin-bottom: 0;
                }
                .label{
                    margin-right: 0.2rem;
                    width: 0.6rem;
                    flex: 0 0 auto;
                    height: 0.6rem;
                    display: inline-block;
                    text-align: center;
                    line-height: 0.6rem;
                    background: #49A3FF;
                    border-radius: 50%;
                    font-size: 0.48rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                .txt{
                    font-size: 0.56rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 0.64rem;
                    color: #333333;
                }
            }
        }
    }
    .yam-box{
        height: 1.9rem;
        // line-height: 1.9rem;
        margin: 0 0.8rem 0.4rem 0.8rem;
        background: #2D7CFF;
        box-shadow: 4px 5px 4px 0px rgb(22, 96, 219,  0.62) inset;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.4rem;
        .txt{
            color: #FFFFFF;
            font-size: 1.12rem;
            font-family: PingFang SC;
            font-weight: 500;
        }
        .copy{
            padding: 0.1rem 0.48rem;
            font-size: 0.56rem;
            font-family: PingFang SC;
            font-weight: 500;
            background: #FFFFFF;
            border-radius: 0.5rem;
            margin-left: 0.4rem;
            max-height: max-content;
            color: #49A3FF;
        }
    }
    .wayone{
        position: relative;
        z-index: 9;
        &::after{
            left: 50%;
            transform: translate(-50%,0);
            bottom: -1.3rem;
            content:  ' ';
            width: 2.3rem;
            height: 2.3rem;
            position: absolute;
            background: url("~@/assets/img/or-icon.png") no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>